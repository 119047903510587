import React from 'react';
import './assets/App.css';
import './assets/utils.css';
import './assets/loader.css';
import RouteModules from './routes';

function App() {
  return (
    <>
      <RouteModules />
    </>
  );
}

export default App;
