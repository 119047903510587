import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import LocalIcons from "../../../assets/svg";
import { Button } from "../../../components/buttons";
import { ProgressBar } from "../../../components/progressBars";
import { useAppDispatch, useAppSelector } from "../../../store";
import { getQuestions, setAnswers, setCurrentQuestionIndex } from "../quizSlice";
import ImageOption from "../../../components/ImageOptions/indes";


const QuizPage = () => {

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { questions, answers, currentQuestionIndex } = useAppSelector(state => state.quiz)
    const question = questions[currentQuestionIndex]
    const answer = answers[currentQuestionIndex]

    const [selectedOption, setSelectedOption] = useState<number>(answer?.optionId)

    useEffect(() => {
        let mounted = true;

        if (mounted && !Object.keys(questions)?.length) {
            dispatch(getQuestions())
        }

        return () => { mounted = false }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onContinue = () => {
        dispatch(setAnswers({
            index: currentQuestionIndex,
            value: {
                questionId: question?.questionId,
                optionId: selectedOption,
            }
        }))

        if (currentQuestionIndex >= Object.keys(questions)?.length -1) {
            navigate("/result")
        } else {
            dispatch(setCurrentQuestionIndex(currentQuestionIndex + 1))
            setSelectedOption(answers[currentQuestionIndex + 1]?.optionId)
        }
    }

    const onGoBack = () => {
        if (currentQuestionIndex === 0) {
            return navigate('/start')
        }
        dispatch(setCurrentQuestionIndex(currentQuestionIndex - 1))
        setSelectedOption(answers[currentQuestionIndex - 1]?.optionId)
    }

    return (
        <main
            className={`relative bg-[#083793] bg-main bg-blend-soft-light bg-cover bg-repeat  w-screen min-h-full flex justify-center text-white px-8 py-8 md:px-[40px] md:pt-[40px]`}
        >

            <div className="max-w-screen-xl w-full grid grid-rows-[7px_max-content_minmax(100px,auto)_56px] md:grid-rows-[10px_max-content_minmax(150px,max-content)_max-content_auto] h-[calc(100vh-64px)] md:h-[calc(100vh-72px)]">

                <ProgressBar progressPercent={((Object.keys(answers)?.length) / Object.keys(questions)?.length) * 100} />

                <div className="pt-[24px] space-y-4">
                    <span className="text-base font-semibold">
                        Question {currentQuestionIndex + 1}/{Object.keys(questions)?.length}
                    </span>
                    <p className="psych-question-paragraph text-2xl md:text-3xl font-medium tracking-[1%] leading-tight font-founders_medium">
                        {question?.question}
                    </p>
                </div>

                <div className="flex flex-col items-center md:items-stretch justify-center md:flex-row gap-6 md:gap-20 py-8 mb-18 md:mb-0 ">
                    {question?.availableOptions.map(option => (
                        <ImageOption imageUrl={option.imageUrl} selected={selectedOption === option.id} onClick={() => setSelectedOption(option.id)} title={option.title} key={option.id} />
                    ))}
                </div>

                <div className="max-w-[473px] mx-auto w-full flex gap-8 mt-0 md:mt-4">
                    <Button variant="inverted" block className="py-3 px-0" onClick={onGoBack} >
                        Back
                    </Button>

                    <Button variant="primary" block className="py-3 px-2" onClick={onContinue} disabled={!Boolean(selectedOption)}>
                        Continue
                    </Button>
                </div>
            </div>
            
        </main>
    )
}

export default QuizPage

