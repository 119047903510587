import React, { FC, ReactNode } from "react";
import RModal from "react-modal"

interface ModalProps {
    open: boolean;
    onClose?(): void;
    closeOnOutsideClick?: boolean
    children: ReactNode
    overlayClassName?: string
}

const Modal: FC<ModalProps> = ({
    open,
    onClose,
    closeOnOutsideClick,
    children,
    overlayClassName,
}) => {
    return (
        <RModal
            isOpen={open}
            onRequestClose={onClose}
            overlayClassName={`w-screen min-h-screen fixed inset-0 z-30 overflow-y-auto flex bg-black/40 backdrop-blur-sm ${
                overlayClassName || "items-center justify-center"
            }`}
            className="p-0 w-fit"
            shouldCloseOnOverlayClick={closeOnOutsideClick}
        >
            {children}
        </RModal>
    )
}

export default Modal

