import React, { useEffect, useState, useRef, useCallback } from "react";
import Confetti from "react-confetti";
import { Button } from "../../../components/buttons";
import Modal from "../../../components/modal";
import { useAppDispatch, useAppSelector } from "../../../store";
import useWindowDimensions from "../../../utils/DOM";
import { submitAnswers } from "../ResultSlice";
import {
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    TwitterShareButton,
    TwitterIcon,
    WhatsappShareButton,
    WhatsappIcon,
    WorkplaceShareButton,
    WorkplaceIcon,
} from "react-share";
import { resultsMap } from "../../../utils/static/result";
import { useNavigate } from "react-router-dom";



const downloadImageResult = (dataUrl: string) => {
    const link = document.createElement('a')
    link.download = 'moniepoint-position.png'
    link.href = dataUrl
    link.target = "_blank"
    link.click()
}


const ResultPage = () => {

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { answers, playerName } = useAppSelector(state => state.quiz)
    const { result, resultLoading, resultImageFile } = useAppSelector(state => state.result)
    const { width, height } = useWindowDimensions()

    const [openShareModal, setOpenShareModal] = useState(false)

    const ref = useRef<HTMLDivElement>(null)


    const downloadShareImage = useCallback((imageUrl: string) => {

        downloadImageResult(imageUrl)


    }, [ref])



    useEffect(() => {
        let mounted = true;

        if (mounted && Object.values(answers).length) {
            dispatch(submitAnswers(
                { answers : Object.values(answers),
                username : playerName}))
        } else {
            navigate("/")
        }

        return () => { mounted = false }
    }, [answers, dispatch, navigate])

    const resultData = resultsMap[result as keyof typeof resultsMap]


    return (
        <>
            <main
                className={`relative w-screen h-screen overflow-y-auto text-white bg-[#083793]  ${resultLoading ? 'bg-main bg-blend-soft-light bg-contain bg-repeat' : 'bg-pitch bg-fixed bg-no-repeat bg-small md:bg-100% bg-top py-8 md:py-24'}`} id="my-node"
            >
                {!resultLoading && <div className="bg-[#083793] opacity-40 absolute w-screen h-screen inset-0 bg-blend-multiply"></div>}
                {
                    resultLoading
                        ? (
                            <div className="w-screen h-screen flex flex-col justify-center items-center relative">
                                <svg className="animate-[spin_1s_linear_infinite] mb-16" width="66" height="62" viewBox="0 0 66 62" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.69256 39.2213C5.14505 36.8145 4.91013 34.294 5.02962 31.7077C5.74331 16.2602 18.8445 4.31612 34.292 5.02982C49.7396 5.74352 61.6836 18.8447 60.9699 34.2922C60.5305 43.8031 55.3954 51.9858 47.9077 56.7043" stroke="#FFF2CA" stroke-width="10" stroke-miterlimit="10" stroke-linecap="round" />
                                </svg>

                                <h1 className="text-sm md:text-[32px] font-medium text-white text-center">
                                    Calculating result, please wait...
                                </h1>
                            </div>
                        )
                        : (
                            <div className="h-[calc(100vh-64px)] md:h-[calc(100vh-192px)] relative grid grid-rows-[max-content_auto_61px]">
                                <h1 className="text-xl font-medium font-founders_medium text-white text-center md:text-5xl capitalize md:mb-8 z-20">
                                    Congratulations {playerName}!!
                                </h1>
                                <section
                                    className="flex flex-col relative z-20"
                                >
                                    <div className="flex flex-col md:flex-row max-w-[1068.5px] mx-auto w-full relative items-center ">
                                        <div className="absolute inset-0 h-full w-full  max-w-[1068.5px] mx-auto ">
                                            <div className="result-details-bg h-full w-full opacity-80 mt-20 sm:mt-[100px] md:mt-0 "></div>
                                        </div>
                                        <img
                                            src={resultData?.jersy} alt="player"
                                            className="relative h-[200px] sm:h-[300px] md:h-[400px] lg:h-auto md:-left-14 md:-top-8"
                                        />

                                        <div className="max-w-[360px] md:max-w-[569px] w-full relative text-center md:text-left  md:-left-20 px-8 md:px-0">
                                            <h4 className="text-2xl font-semibold md:font-medium leading-none font-founders_medium text-white pb-2 md:text-4xl lg:text-5xl">
                                                You Are A {result}
                                            </h4>
                                            <p className="text-sm font-semibold  text-white pb-2 lg:pb-4 md:text-base lg:text-xl">
                                                {resultData?.description?.line1}
                                            </p>
                                            <p className="text-sm font-semibold  text-white pb-2 md:text-base lg:text-xl">
                                                {resultData?.description?.line2}
                                            </p>
                                        </div>

                                    </div>
                                </section>
                                <div className="w-full sm:max-w-xs md:max-w-lg mx-auto  px-5 flex items-center justify-center space-x-4 z-20" id="button-group">
                                    <Button
                                        variant="inverted"
                                        onClick={() => downloadShareImage(resultImageFile)}
                                        block
                                    >
                                        Download
                                    </Button>

                                    <Button
                                        variant="primary"
                                        onClick={() => setOpenShareModal(true)}
                                        block
                                    >
                                        Share
                                    </Button>
                                </div>

                            </div>
                        )
                }
            </main>

            <Modal
                open={openShareModal}
                onClose={() => setOpenShareModal(false)}
                overlayClassName="items-end justify-end md:items-center md:justify-center"
            >
                <section className="w-screen h-[250px] bg-[#f2f4f8] shadow-2xl rounded-t-[30px] md:w-[380px] md:rounded-3xl md:bg-[#092256]">
                    <div>
                        <h4 className="text-2xl font-extrabold px-4 pt-12 md:text-white md:pt-8">Share Result via</h4>
                    </div>
                    <div className="w-full overflow-x-auto flex items-center justify-center px-2 pt-6 space-x-2">
                        <FacebookShareButton
                            url={resultImageFile}
                        >
                            <FacebookIcon size={60} round />
                            <span className="text-xs md:text-white">Facebook</span>
                        </FacebookShareButton>

                        <LinkedinShareButton
                            url={resultImageFile}
                        >
                            <LinkedinIcon size={60} round />
                            <span className="text-xs md:text-white">LinkedIn</span>
                        </LinkedinShareButton>

                        <TwitterShareButton
                            url={resultImageFile}
                        >
                            <TwitterIcon size={60} round />
                            <span className="text-xs md:text-white">Twitter</span>
                        </TwitterShareButton>

                        <WhatsappShareButton
                            url={resultImageFile}
                        >
                            <WhatsappIcon size={60} round />
                            <span className="text-xs md:text-white">WhatsApp</span>
                        </WhatsappShareButton>

                        <WorkplaceShareButton
                            url={resultImageFile}
                        >
                            <WorkplaceIcon size={60} round />
                            <span className="text-xs md:text-white">Workplace</span>
                        </WorkplaceShareButton>
                    </div>
                </section>
            </Modal>
            {!resultLoading && <Confetti
                className="max-w-screen w-full h-full"
                width={width}
                height={height}
            />}
        </>
    )
}

export default ResultPage

