import React, { useEffect, useState } from 'react'
import PlayerNameJersey from '../../assets/svg/PlayerNameJersey.png'
import { TextInput } from '../../components/input'
import { Button } from '../../components/buttons/index';
import { setPlayerName } from "../Quiz/quizSlice";
import { useAppDispatch, useAppSelector } from '../../store';
import { useNavigate } from 'react-router-dom';
type Props = {}

const PlayerName = (props: Props) => {
    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { playerName } = useAppSelector(state => state.quiz)
    const [name, setName] = useState("")

    const handleNameChange = (event: { target: { value: React.SetStateAction<string> } }) => {
        setName(event.target.value)
    }

    const getFontSize = () => {
        if (name?.length < 12) {
            return 26.37
        }
        else if (name?.length >= 20) { return 19 }
        else {
            return Math.ceil(26.37 - (name?.length - 12) * (7.37 / 8))
        }
    }

    const onProceed = () => {
        dispatch(setPlayerName(name))
        navigate("/quiz")
    }

    useEffect(() => {
        let mounted = true;
        if (mounted) {
            setName(playerName)
        }
        return () => { mounted = false }
    }, [playerName])

    return (
        <main
            className={`relative landing-screen-bg   w-screen min-h-full flex justify-center text-white px-[32px] pt-24 md:px-[40px] md:pt-10`}
        >
            <div className='flex flex-col'>
                <div className="text-2xl md:text-5xl font-medium font-founders_medium text-white text-center mb-11">Enter Player Name</div>
                <div className="relative overflow-clip">
                    <img src={PlayerNameJersey} className='w-full max-w-3xl max-h-[450px] object-cover' alt="" />
                    <div className={`font-semibold uppercase font-founders_medium absolute inset-0 -top-8 md:-top-12`}>
                        <svg className='w-full' viewBox="0 0 500 500" fill="none" xmlns="http://www.w3.org/2000/svg">

                            <path id="curve" d="M73 204.336C77.0034 201.444 138.556 158.437 251.751 159.006C363.146 159.575 422.696 201.823 427 205" />
                            <text className='fill-[#0B3275] '>
                                <textPath style={{ fontSize: getFontSize() + 'px' }} startOffset="50%" dominantBaseline="middle" textAnchor="middle" xlinkHref="#curve">
                                    {name || 'USERNAME'}
                                </textPath>
                            </text>
                        </svg>
                    </div>
                </div>
                <div className="max-w-xs w-full mx-auto">
                    <TextInput label='Username' name="name" value={name} onChange={handleNameChange} maxLength={20} />
                </div>
                <Button className="mt-16 md:mt-6 md:w-full mx-auto px-16 md:max-w-xs" disabled={name?.length < 1} onClick={onProceed}>Proceed</Button>
                <div>

                </div>
            </div>
        </main>
    )
}

export default PlayerName