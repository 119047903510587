import Kuwego from "./kuwego.svg";
import PlainVerctorBgLanding from "./PlainVerctorBgLanding.png"
import LandingPageBlueVector from "./LandingPageBlueVector.svg"
import LandingPageYellowVector from "./LandingPageYellowVector.svg"
import MoniepointM from "./MoniepointM.svg"
import Group_5 from "./Group_5.svg"
import Group_1 from "./Group_1.svg"
import Icy_Rectangle from "./Icy_Rectangle.svg"
import Icy_Rectangle1 from "./Icy_Rectangle1.svg"
import PlayerJersyGoalKeeper from "./PlayerJersyGoalKeeper.png"
import PlayerJersyDefender from "./PlayerJersyDefender.png"
import PlayerJersyMidfielder from "./PlayerJersyMidfielder.png"
import PlayerJersyStriker from "./PlayerJersyStriker.png"

const LocalIcons = {
    Kuwego,
    PlainVerctorBgLanding,
    LandingPageBlueVector,
    LandingPageYellowVector,
    MoniepointM,
    Group_5,
    Group_1,
    Icy_Rectangle,
    Icy_Rectangle1,
    PlayerJersyGoalKeeper,
    PlayerJersyDefender,
    PlayerJersyMidfielder,
    PlayerJersyStriker,
}

export default LocalIcons
