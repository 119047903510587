import React, { FC, Suspense } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import { Loading } from "../components/Loaders";
import LandingPage from "../modules/Landing";
import QuizPage from "../modules/Quiz/quizPage";
import ResultPage from "../modules/Results/ResultPage";
import PlayerName from "../modules/PlayerName";




const RouteModules: FC = () => {
    return (
            <BrowserRouter>
            <Suspense fallback={<Loading />}>
                <Routes>
                    <Route path="/" element={<LandingPage />} />
                    <Route path="/start" element={<PlayerName />} />
                    <Route path="/quiz" element={<QuizPage />} />
                    <Route path="/result" element={<ResultPage />} />
                    <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
            </Suspense>
        </BrowserRouter>
    )
}


export default RouteModules
