import server from "../../../server";
import { IServerResponse } from "../../../server/types";
import { IQuestion } from "./quizAPI.types";


const allQuestionsUrl = "psych-questionnaires";

export const api_get_questions = async () => {
    const response = await server.get(allQuestionsUrl);
    return response.data as IServerResponse<IQuestion[]>;
};
