import LocalIcons from "../../assets/svg";

export const resultsMap = {
    GOALKEEPER: {
        title: "GOALKEEPER",
        description: {
            line1: `Now, you are the last line of defense for your team.
            The one who ensures that all projects are up to scratch with quality standards.
            Your eyes are always on the ball, and your analytical mind works full blast in predicting possible outcomes for your team work and strategy.`,
            line2: `Nothing slips through the cracks with you, and your attention to detail is unrivalled. You are truly a unique member of your team and as a company,
            we can’t do without you!`
        },
        jersy: LocalIcons.PlayerJersyGoalKeeper,
        image: "https://res.cloudinary.com/dqnxpdufw/image/upload/v1669726518/psych-questionnaire-images/gk_result_iik2ve.jpg",
        resultImageBg : "url(/images/results/goal_keeper_result_screen.png)",
        titleTextColor: "#ffffff"
    },
    DEFENDER: {
        title: "DEFENDER",
        description: {
            line1: `Based on your answers, you are the type of person who has got the back of every member of your team. 
            You’re willing to support them in any capacity - even if it’s out of your core responsibilities. 
            You are dependable, and the usual “go-to” person and no team is complete without you.`,
            line2: `So, next time you join a team session, join with the pride of knowing you are priceless and invaluable member of not just your team, but of the entire company.`,
        },
        jersy: LocalIcons.PlayerJersyDefender,
        image: "https://res.cloudinary.com/dqnxpdufw/image/upload/v1669726399/psych-questionnaire-images/df_result_lospu4.jpg",
        resultImageBg : "url(/images/results/defender_result_screen.png)",
        titleTextColor: "#ffffff"
    },
    MIDFIELDER: {
        title: "MIDFIELDER",
        description: {
            line1: `Listen, you are more important than you think! As a midfielder, you are the connection that holds your team together. 
            The One With The Power Of Co-ordination. You are the one that ensures that the ball is passed to the right people. With your coordination skills, 
            your team is even closer to success.`,
            line2: `You are depended upon to manage projects. You facilitate effective communication that leads to a much better working environment overall. You are one in a million; always remember that!`,
        },
        jersy: LocalIcons.PlayerJersyMidfielder,
        image: "https://res.cloudinary.com/dqnxpdufw/image/upload/v1669726503/psych-questionnaire-images/mf_result_xlpnvr.jpg",
        resultImageBg : "url(/images/results/midfielder_result_screen.png)",
        titleTextColor: "#1c2654"
    },
    STRIKER: {
        title: "STRIKER",
        description: {
            line1: `You are a go-getter: someone who doesn’t take “no” for an answer. In your dictionary, “impossibility” is an impossibility. Your eyes are always on the goal and you believe that there is always a way to achieve it. Your teammates depend on you to make the move that brings success, and even if your first few attempts don’t work, your never-give-up spirit “gingers” your team to keep trying till you score!`,
            line2: `You are a very key element your team needs to stay motivated. And you’re not just invaluable to your team, you’re invaluable to all of us`,
        },
        jersy: LocalIcons.PlayerJersyStriker,
        image: "https://res.cloudinary.com/dqnxpdufw/image/upload/v1669726532/psych-questionnaire-images/striker_result_nikc0n.jpg",
        resultImageBg : "url(/images/results/striker_result_screen.png)",
        titleTextColor: "#ffffff"
    }
}