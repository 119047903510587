import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LocalIcons from "../../assets/svg";
import { Button } from "../../components/buttons";
import { useAppDispatch, useAppSelector } from "../../store";
import { getQuestions } from "../Quiz/quizSlice";
import { FootBallIcon } from "../../components/Icons";


const LandingPage = () => {

    const navigate = useNavigate()
    const dispatch = useAppDispatch()
    const { questions } = useAppSelector(state => state.quiz)

    useEffect(() => {
        let mounted = true;

        if (mounted && !Object.keys(questions).length) {
            dispatch(getQuestions())
        }

        return () => { mounted = false }
        
    }, [dispatch, questions])

    return (
        <main
            className={`landing-screen-bg w-screen h-full flex flex-col items-center justify-center text-white px-8`}
        >
            <img src={LocalIcons.MoniepointM} alt="moniepoint M" className="w-[10rem] mb-8 md:w-[12rem] md:md-10" />

            <span className="text-2xl font-semibold text-center md:text-[2.5rem] font-founders_medium">
                Welcome to the
            </span>
            <h1
                className="text-[2.5rem] font-bold text-center text-white md:text-8xl font-founders_medium"
            >
                Dream Team Quiz
            </h1>
            <span className="text-base font-normal text-center md:text-2xl mt-2 md:mt-6">
                Take this quiz to know what your personality says about your contribution to teamwork.
            </span>

            <Button className="flex font-medium space-x-2 font-inter mt-12 py-4 px-12  md:px-14" onClick={() => navigate("/start")}>
                <FootBallIcon />
                <span className="flex gap-1"><span>Start</span> <span className="hidden md:flex">Quiz</span></span>
            </Button>
        </main>
    )
}

export default LandingPage

