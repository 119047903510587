import server from "../../../server"
import { IServerResponse } from "../../../server/types";
import {IAnswer, IPsychProfileRequest} from "../../Quiz/quizAPI/quizAPI.types";

const allQuestionsUrl = "psych-questionnaires";



export const api_submit_answers = async (payload: IPsychProfileRequest) => {
    const response = await server.post(
        allQuestionsUrl,
        payload
    )

    return response.data as IServerResponse<any>
}
