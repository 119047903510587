import CONFIG_PRODUCTION from "./config-production";
import CONFIG_STAGING from "./config-staging";

type environments = "playground" | "test" | "dev" | "staging" | "production";

const HOLDER = {
  VERSION: "0.0.1",
  BASE_URL: "https://psych-questionnaire.moniepoint.com/api/v1/",
};

const configMap = {
  playground: HOLDER,
  test: HOLDER,
  dev: HOLDER,
  staging: CONFIG_STAGING,
  production: CONFIG_PRODUCTION,
};

const config =
  configMap[
    (process.env.REACT_APP_ENV as environments)
      ? (process.env.REACT_APP_ENV as environments)
      : "dev"
  ];

export default config;
