import { combineReducers } from '@reduxjs/toolkit'
import quizReducer from '../modules/Quiz/quizSlice'
import resultReducer from '../modules/Results/ResultSlice'



export const rootReducer = combineReducers({
    quiz: quizReducer,
    result: resultReducer,
})
