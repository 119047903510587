import React from 'react'

type IconProps = {
    className?: string;
    height?: number;
    width?: number;
}

export const FootBallIcon: React.FC<IconProps> = ({ className = '', height = 25, width = 25 }) => (
    <svg className={className} width={width} height={height} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.1505 19.4395L7.80322 19.9867C8.99465 20.6772 10.3349 21.0707 11.7104 21.1339L10.1505 19.4395Z" fill="black" />
        <path d="M16.0352 9.96903L16.2752 14.5626L17.7152 15.633L20.5472 14.4666H20.5471C20.8657 13.102 20.8476 11.6803 20.4943 10.3242L17.3455 9.00903L16.0352 9.96903Z" fill="black" />
        <path d="M18.2433 6.38345C17.9773 6.12752 17.6969 5.8871 17.4033 5.66345L17.7009 8.12105L20.0769 9.13382C19.6503 8.10658 19.0275 7.1723 18.2433 6.38345H18.2433Z" fill="black" />
        <path d="M6.00801 6.38341C5.06717 7.32788 4.35893 8.47806 3.93921 9.74341L5.85921 12.5801L7.50561 12.4553L10.0689 8.53372L9.24321 6.76732L6.16164 6.25372L6.00801 6.38341Z" fill="black" />
        <path d="M3.91046 15.177L5.02406 13.0891L3.632 10.8858C3.3636 12.3168 3.45923 13.7924 3.91043 15.177H3.91046Z" fill="black" />
        <path d="M18.2433 18.6186C19.1025 17.7566 19.7698 16.7229 20.2017 15.585L18.0897 16.5114L17.8352 18.9835C17.9745 18.8635 18.1136 18.7338 18.2433 18.6187L18.2433 18.6186Z" fill="black" />
        <path d="M11.9265 3.85383L10.1025 6.37867L10.9281 8.17383L15.4353 9.2442L16.7601 8.2842L16.3617 5.0154C15.0142 4.23742 13.4822 3.83614 11.9265 3.85383H11.9265Z" fill="black" />
        <path d="M5.8832 13.5306L4.34717 16.2618C4.76343 17.1358 5.325 17.9327 6.008 18.6187C6.27358 18.8687 6.55229 19.1042 6.84317 19.3242L9.97764 18.4987L10.5873 16.8811L7.5487 13.4059L5.8832 13.5306Z" fill="black" />
        <path d="M9.32975 5.81225L10.6498 3.98828C9.38907 4.20123 8.19188 4.69316 7.14575 5.42828L9.32975 5.81225Z" fill="black" />
        <path d="M15.3105 14.5098L15.08 10.1226L10.808 9.10986L8.35522 12.8683L11.2496 16.2042L15.3105 14.5098Z" fill="black" />
        <path d="M16.8898 19.7226L17.1346 16.4059L15.7474 15.3738L11.4946 17.145L10.8706 18.8059C10.8706 18.8059 12.8722 21.0235 12.8866 21.1147C14.3165 20.9928 15.6929 20.5141 16.8898 19.7226L16.8898 19.7226Z" fill="black" />
    </svg>
)