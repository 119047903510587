import { createSlice } from "@reduxjs/toolkit";
import { AppThunk } from "../../../store";
import { IPsychProfileRequest} from "../../Quiz/quizAPI/quizAPI.types";
import { api_submit_answers } from "../ResultAPI";
import { IResultState } from "./resultSlice.types";


const initialState: IResultState = {
    result: "",
    resultError: "",
    resultLoading: false,
    resultImageFile : ""
}


const resultSlice = createSlice({
    name: "result",
    initialState,
    reducers: {
        setResult: (state, action) => {
            state.result = action.payload
        },
        setResultError: (state, action) => {
            state.resultError = action.payload
        },
        setResultLoading: (state, action) => {
            state.resultLoading = action.payload
        },
        setResultImageFile: (state, action) => {
            state.resultImageFile = action.payload
        }
    }
})


const { setResult, setResultError, setResultLoading, setResultImageFile } = resultSlice.actions
const resultReducer = resultSlice.reducer


export const submitAnswers = (payload: IPsychProfileRequest): AppThunk =>
    async (dispatch) => {
        try {
            dispatch(setResultLoading(true))

            const { data } = await api_submit_answers(payload)
            dispatch(setResult(data.teamPosition))
            //hardcoded format of the url for result
            const resultFileLink = `https://psych-questionnaire.moniepoint.com/api/v1/psych/file/result/${data.evaluationImageReference}`

            dispatch(setResultImageFile(resultFileLink))

        } catch (error: any) {
            dispatch(setResultError(error?.response?.data?.message))
        } finally {
          dispatch(setResultLoading(false))
        }
    }

export default resultReducer
