import { createSlice } from '@reduxjs/toolkit';
import { AppThunk } from '../../../store';
import { api_get_questions } from '../quizAPI';
import { IQuizState } from './quizSlice.types';
import {IQuestion} from "../quizAPI/quizAPI.types";



const initialState: IQuizState = {
    questions: {},
    currentQuestionIndex: 0,
    loadQuestionsError: "",
    questionsLoading: false,
    answers: {},
    playerName: ""
};


const quizSlice = createSlice({
    name: "quiz",
    initialState,
    reducers: {
        loadQuestions: (state, action) => {
            state.questions = action.payload
        },
        setLoadQuestionsError: (state, action) => {
            state.loadQuestionsError = action.payload
        },
        setQuestionsLoading: (state, action) => {
            state.questionsLoading = action.payload
        },
        setCurrentQuestionIndex: (state, action) => {
            state.currentQuestionIndex = action.payload
        },
        setAnswers: (state, action) => {
            state.answers[action.payload.index] = action.payload.value
        },
        setPlayerName: (state, action) => {
            state.playerName = action.payload
        }
    }
})

export const { loadQuestions, setLoadQuestionsError, setQuestionsLoading, setCurrentQuestionIndex, setAnswers, setPlayerName } = quizSlice.actions
const quizReducer = quizSlice.reducer


const preDownloadQuestionsAnswerThumbnails = (downloadedQuestions : IQuestion[]) => {
    for (let i = 0 ; i < Object.keys(downloadedQuestions).length ; i++){
        let availableOptions = downloadedQuestions[i].availableOptions
        for(let k = 0; k < Object.keys(availableOptions).length; k++){
            const img = new Image()
            img.src = availableOptions[k].imageUrl
        }
    }
}
export const getQuestions =
    (): AppThunk =>
        async (dispatch) => {
            try {
                dispatch(setQuestionsLoading(true))
                const { data } = await api_get_questions();
                const questions = Object.assign({}, data)
                dispatch(loadQuestions(questions))
                preDownloadQuestionsAnswerThumbnails(questions)
            } catch (error: any) {
                dispatch(setLoadQuestionsError(error?.response?.data?.message ))
            } finally {
                dispatch(setQuestionsLoading(false))
            }
        };


export default quizReducer

