import React from 'react'

type Props = {
    title: string;
    imageUrl: string;
    selected: boolean;
    onClick: () => void;
}

const ImageOption = (props: Props) => {
    return (
        <div className={` max-w-[200px] md:max-w-[450px] w-full md:max-h-[520px] cursor-pointer overflow-clip  ${props.selected ? 'bg-PrimaryYellow/20' : 'bg-black/20'} transition-all duration-300 ease-in-out  rounded-[20px] shadow-lg shadow-black/20 relative flex flex-col justify-start pt-6 md:pt-20 p-4  md:p-8 items-center gap-4 md:gap-8`}
            onClick={props.onClick}>
            <div className="absolute w-full h-full bg-trasparent z-10 inset-0"></div>
            <div className="absolute top-4 left-4  md:top-8  md:left-8 rounded-full h-4 w-4 md:h-8 md:w-8 border-2 md:border-[3px] border-PrimaryYellow transition-all duration-300 p-[1px] md:p-0.5 flex justify-center items-center">
                <div className={`w-full h-full rounded-full transition-all duration-300 ${props.selected ? 'bg-PrimaryYellow' : 'bg-transparent'}`}></div>
            </div>
            <div className="mask-moniepoint flex justify-center max-w-[155.52px] md:max-w-[293px] w-full overflow-hidden object-cover" style={{ aspectRatio: 1 }}>
                <img src={props.imageUrl} style={{ height : "100%" , display : "block", maxWidth: "max-content"}} alt={props.title} loading='lazy' />
            </div>
            <span className="text-base md:text-[32px] font-founders_medium leading-none font-medium text-center">
                {props.title}
            </span>
        </div>
    )
}

export default ImageOption