import classNames from "classnames";
import React, { ButtonHTMLAttributes, FC } from "react";



export interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
    placeholder?: string;
    variant?: "primary" | "secondary" | "cancel" | "inverted";
    loading?: boolean;
    icon?: any;
    disabled?: boolean;
    block?: boolean;
}

export const Button: FC<IButton> = ({
    placeholder,
    variant = "primary",
    loading,
    block = false,
    className,
    ...rest
}) => {
    const buttonVariant = classNames({
        "bg-PrimaryYellow text-black hover:bg-PrimaryYellow/80": variant === "primary" && !rest.disabled,
        "bg-PrimaryYellow/20 text-white hover:bg-PrimaryYellow/30": variant === "inverted" && !rest.disabled,
          "bg-grey-darkBg text-white":
            variant === "secondary" && !rest.disabled,
        "bg-black/10 text-white/40 font-bold": rest.disabled,
    });

    return (
        <button className={`rounded min-w-[100px] px-4 h-12 md:h-14 font-inter font-medium transition-all duration-300 ease-in-out ${block ? 'w-full' : ''} ${rest.disabled ? 'cursor-not-allowed' : ''} ${buttonVariant} ${className}`} {...rest}>
            {placeholder || rest.children}
        </button>
    );
};

