import classNames from "classnames";
import React, { FC, InputHTMLAttributes, ReactNode, useCallback, useRef } from "react";

export interface TextInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, 'prefix'> {
    label?: string;
    prefix?: ReactNode;
    suffix?: ReactNode;
    name: string;
    value: any;
    disabled?: boolean
    error?: string;
}

export const TextInput: FC<TextInputProps> = ({
    label,
    placeholder,
    prefix,
    suffix,
    name,
    value,
    disabled,
    type = "text",
    onChange,
    error,
    ...props
}) => {
    const [focus, setFocus] = React.useState(false);
    const inputEl: any = useRef(null);
    const hasValue = useCallback(() => {
        return (value && value?.toString().length > 0) || (props.defaultValue && props.defaultValue?.toString().length > 0) || !!inputEl?.current?.value || false
    }, [props.defaultValue, value])

    function handleFocus(event: React.FocusEvent<HTMLInputElement, Element>) {
        setFocus(true)
        if (props.onFocus) {
            props?.onFocus(event)
        }
    }
    function handleBlur(event: React.FocusEvent<HTMLInputElement, Element>) {
        setFocus(false)
        if (props.onBlur) {
            props?.onBlur(event)
        }
    }

    const errorBorder = classNames({
        "border-PrimaryYellow": !!!error,
        "border-red-500": !!error,
    });
    const labelClass = classNames({
        "bottom-2 text-2xl": !hasValue() && !focus,
        "bottom-9 text-lg": hasValue() || focus,
    })

    const onLabelClick = () => {
        inputEl.current.focus();
    };


    return (
        <div className="relative w-full">
            <div className="relative w-full">
                <label onClick={onLabelClick} className={`absolute text-white/60 transition-all duration-200 ease-in-out cursor-text ${labelClass}`}>{label || placeholder}</label>
                <input
                    ref={inputEl}
                    type={type}
                    value={value}
                    name={name}
                    disabled={disabled}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onChange={onChange}
                    className={`bg-transparent autofill:bg-transparent border-b-[3px] ring-0 outline-none w-full h-[41px] transition-all duration-200 ease-in-out text-2xl font-white font-semibold  ${errorBorder} input ${focus || hasValue() ? 'py-1' : ''}`}
                    {...props}
                />
            </div>
            {!!error ? <span className="text-xs text-red-500">{error}</span> : <div className="text-right  text-sm mt-2">max 20 characters</div>}

        </div>
    );
};