import React, { FC } from "react";

export enum ProgressBarSize {
    sm = "sm",
    md = "md",
    lg = "lg"
}

interface ProgressBarProps {
    color?: string
    progressPercent: number
}

export const ProgressBar: FC<ProgressBarProps> = ({ progressPercent }) => {

    return (
        <div className={`bg-grey-silentBg h-[7px] md:h-[10px] w-full rounded-[25px]`}>
            <div
                className={`transition-all transform duration-500 h-full bg-white rounded-[25px]`}
                style={{ width: `${progressPercent}%`}}
            />
        </div>
    )
}

